import React from 'react';

import { graphql } from 'gatsby';

import Button from '../../components/Button/Button';
import DotCube from '../../components/Decorations/DotCube/DotCube';
import Layout from '../../components/Layout/Layout';
import NotFoundSEO from '../../components/Seo/NotFoundSEO';
import MainTitle from '../../components/Title/MainTitle/MainTitle';

import * as css from './404.module.scss';

const PageNotFound = ({ data }: { data: any }) => {
  const imageOg = data.allImageSharp.edges[0]?.node.fluid.src;
  return (
    <Layout>
      <NotFoundSEO imageUrl={imageOg} />
      <div className="container-flex">
        <div style={{ marginTop: '50px' }} className="container">
          <div className={css.container}>
            <MainTitle
              titleId="PageNotFound.mainTitle.title"
              subtitleId="PageNotFound.mainTitle.subtitle"
            >
              <div className={css.buttonContainer}>
                <Button
                  link="/"
                  titleId="PageNotFound.backButton.title"
                  variant="secondary"
                />
              </div>
            </MainTitle>
          </div>
        </div>
      </div>
      <div className={css.container}>
        <div className={css.decorations}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1610 790"
            className={css.elipse}
          >
            <ellipse cx="805" cy="673.5" fill="#1463f3" rx="805" ry="518.5" />
            <ellipse cx="805" cy="575" fill="url(#a)" rx="805" ry="575" />
            <defs>
              <linearGradient
                id="a"
                x1="805"
                x2="799.3"
                y1="15.5"
                y2="310.4"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1463f3" />
                <stop offset="1" stopColor="#7A361C" />
              </linearGradient>
            </defs>
          </svg>
          <div className={css.dotCubeContainer}>
            <div className={css.dotcube}>
              <DotCube cubeSize={3} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;

export const query = graphql`
  query MyQuery {
    allImageSharp(filter: { fluid: { originalName: { eq: "App1.png" } } }) {
      edges {
        node {
          id
          fluid {
            src
          }
        }
      }
    }
  }
`;
