import React from 'react';

import * as css from './DotCube.module.scss';

type DotCubeType = {
  cubeSize: number;
};

const DotCube = ({ cubeSize }: DotCubeType) => {
  const dots = [];
  for (let i = 0; i < cubeSize * cubeSize; i++) {
    dots.push(<div className={css.dot} />);
  }

  return (
    <div
      style={{ width: `${cubeSize * 24}px`, height: `${cubeSize * 24}px` }}
      className={css.container}
    >
      {dots}
    </div>
  );
};

export default DotCube;
